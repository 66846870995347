<template>
  <v-row>
    <!-- Row Editing via dialog -->
    <v-col cols="12">
      <DatatableRowEditingViaDialog></DatatableRowEditingViaDialog>
    </v-col>
  </v-row>
</template>

<script>
import DatatableRowEditingViaDialog from '@/components/DatatableRowEditingViaDialog.vue'

import store from '@/store'

export default {
  components: {
    DatatableRowEditingViaDialog,
  },
  setup() {
    return {
      test: () => store.dispatch('setting_bank/readSettingBank'),
    }
  },
}
</script>
