<template>
  <div>
    <v-row
      align="center"
      justify="start"
    >
      <h1>銀行設定</h1>
      <v-btn
        color="primary"
        class="btn btn-wd mx-1 my-3"
        outlined
        @click="createItem()"
      >
        <v-icon
          class="me-2"
        >
          {{ icons.mdiPlusBoxOutline }}
        </v-icon>
        新規登録
      </v-btn>
    </v-row>
    <v-data-table
      id="setting-bank-table"
      :loading="loading"
      loading-text="読込中"
      no-data-text="データがありません"
      :headers="headers"
      :items="settingBankList"
      sort-by="calories"
    >
      <!-- action -->
      <template #[`item.actions`]="{ item }">
        <v-icon
          color="success"
          class="me-2"
          @click="editItem(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
        <v-icon
          color="error"
          @click="deleteItem(item)"
        >
          {{ icons.mdiDeleteOutline }}
        </v-icon>
      </template>

      <!-- name -->
      <template #[`item.full_name`]="{item}">
        <div class="d-flex align-center">
          <v-avatar
            :color="item.avatar ? '' : 'primary'"
            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img
              v-if="item.avatar"
              :src="require(`@/assets/images/avatars/${item.avatar}`)"
            ></v-img>
            <span v-else>{{ item.full_name.slice(0,2).toUpperCase() }}</span>
          </v-avatar>
          <div class="d-flex flex-column ms-3">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
            <small>{{ item.post }}</small>
          </div>
        </div>
      </template>

      <!-- salary -->
      <template #[`item.salary`]="{item}">
        {{ `$${item.salary}` }}
      </template>
    </v-data-table>

    <!-- dialog -->
    <v-dialog
      v-model="dialogEdit"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">編集</span>
        </v-card-title>
        <v-card-text>
          内容をよく確認して、銀行情報を編集してください.
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-form
              v-model="isValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_name"
                    :rules="[required]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行名
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_name_kana"
                    :rules="[required, hankakukana]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_code"
                    :rules="[required,validNumLength_4]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行コード
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_name"
                    :rules="[required]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店名
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_name_kana"
                    :rules="[required, hankakukana]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_code"
                    :rules="[required, validNumLength_3]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店コード
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-select
                    v-model="editedItem.acc_type_name"
                    :rules="[required]"
                    :items="selectOptions"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      口座種別
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.acc_num"
                    :rules="[required,validNumLength_7]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      口座番号
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.consigner_name"
                    :rules="[required, hankakukana]"
                    label="委託者名（半角ｶﾅ）"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      委託者名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.consigner_code"
                    :rules="[required, validNumLength_10]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      委託者コード（番号）
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeEdit"
          >
            キャンセル
          </v-btn>
          <v-btn
            :disabled="!isValid"
            color="primary"
            @click="updateItemConfirm"
          >
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog -->
    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="50%"
    >
      <v-card>
        <v-card-title>
          この設定は完全に削除され、復元することはできません。本当に削除しますか？
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="closeDelete"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="error"
            outlined
            @click="deleteItemConfirm"
          >
            削除
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog -->
    <v-dialog
      v-model="dialogCreate"
      persistent
      max-width="60%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">新規登録</span>
        </v-card-title>
        <v-card-text>
          内容をよく確認して、銀行情報を登録してください.
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-form
              v-model="isValid"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_name"
                    :rules="[required]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行名
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_name_kana"
                    :rules="[required, hankakukana]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.bank_code"
                    :rules="[required,validNumLength_4]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      銀行コード
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_name"
                    :rules="[required]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店名
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_name_kana"
                    :rules="[required, hankakukana]"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.branch_code"
                    :rules="[required, validNumLength_3]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      支店コード
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="3"
                >
                  <v-select
                    v-model="editedItem.acc_type_name"
                    :rules="[required]"
                    :items="selectOptions"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      口座種別
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    v-model="editedItem.acc_num"
                    :rules="[required,validNumLength_7]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      口座番号
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.consigner_name"
                    :rules="[required, hankakukana]"
                    label="委託者名（半角ｶﾅ）"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      委託者名（半角ｶﾅ）
                    </template>
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedItem.consigner_code"
                    :rules="[required, validNumLength_10]"
                    type="number"
                  >
                    <template v-slot:label>
                      <span style="color: red"> *</span>
                      委託者コード（番号）
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeCreate"
          >
            キャンセル
          </v-btn>
          <v-btn
            :disabled="!isValid"
            color="primary"
            @click="createItemConfirm"
          >
            新規登録
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPencilOutline, mdiDeleteOutline, mdiPlusBoxOutline } from '@mdi/js'

export default {
  data: () => ({
    isValid: false,
    dialogEdit: false,
    dialogDelete: false,
    dialogCreate: false,
    loading: false,
    icons: {
      mdiDeleteOutline,
      mdiPencilOutline,
      mdiPlusBoxOutline,
    },
    headers: [
      { text: '編集・削除', value: 'actions' },
      { text: '委託者名（ｶﾅ）', value: 'consigner_name' },
      { text: '委託者コード', value: 'consigner_code' },
      { text: '銀行名', value: 'bank_name' },
      { text: '銀行名（ｶﾅ）', value: 'bank_name_kana' },
      { text: '銀行コード', value: 'bank_code' },
      { text: '支店名', value: 'branch_name' },
      { text: '支店名（ｶﾅ）', value: 'branch_name_kana' },
      { text: '支店コード', value: 'branch_code' },
      { text: '口座種別', value: 'acc_type_name' },
      { text: '口座番号', value: 'acc_num' },
    ],
    selectOptions: [
      { text: '普通', value: '普通' },
      { text: '当座', value: '当座' },
    ],
    statusColor: {
      /* eslint-disable key-spacing */
      Current: 'primary',
      Professional: 'success',
      Rejected: 'error',
      Resigned: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    },
    settingBankList: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    initItem: {
      acc_num: null,
      acc_type_name: null,
      bank_code: null,
      bank_name: null,
      bank_name_kana: null,
      branch_code: null,
      branch_name: null,
      branch_name_kana: null,
      consigner_code: null,
      consigner_name: null,
      customer_id: null,
      setting_bank_id: null,
    },
  }),

  watch: {
    dialogEdit(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeEdit()
    },
    dialogDelete(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeDelete()
    },
    dialogCreate(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.closeCreate()
    },
  },

  async created() {
    await this.initialize()
  },

  methods: {
    required: v => !!v || '入力必須',
    validNumLength_3: v => (!!v && v.length <= 3) || '3桁以内で入力してください',
    validNumLength_4: v => (!!v && v.length <= 4) || '4桁以内で入力してください',
    validNumLength_7: v => (!!v && v.length <= 7) || '7桁以内で入力してください',
    validNumLength_10: v => (!!v && v.length <= 10) || '10桁以内で入力してください',
    hankakukana: v => (!!v && !!v.match(/^[ｦ-ﾟ 0-9 -~]*$/)) || '半角ｶﾅで入力してください',
    async loadData() {
      this.loading = true

      await this.$store.dispatch('setting_bank/readSettingBank')

      this.loading = false
    },
    async initialize() {
      console.log('initialize....')
      await this.loadData()
      this.settingBankList = this.$store.getters['setting_bank/getSettingBank']
    },

    createItem() {
      this.editedItem = { ...this.initItem }
      this.dialogCreate = true
    },

    editItem(item) {
      this.editedIndex = this.settingBankList.indexOf(item)
      this.editedItem = { ...item }
      this.dialogEdit = true
    },

    deleteItem(item) {
      this.editedIndex = this.settingBankList.indexOf(item)
      this.editedItem = { ...item }
      console.log(this.editedItem.setting_bank_id)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.settingBankList.splice(this.editedIndex, 1)

      await this.$store.dispatch('setting_bank/deleteSettingBank', {
        params: {
          setting_bank_id: this.editedItem.setting_bank_id,
        },
      })

      this.closeDelete()
      await this.initialize()
    },

    closeEdit() {
      this.dialogEdit = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
    },

    closeCreate() {
      this.dialogCreate = false
    },

    async updateItemConfirm() {
      console.log(this.editedItem.setting_bank_id)

      await this.$store.dispatch('setting_bank/updateSettingBank', {
        params: {
          setting_bank_id: this.editedItem.setting_bank_id,
        },
        data: {
          item_data: this.editedItem,
        },
      })

      this.closeEdit()
      await this.initialize()
    },

    async createItemConfirm() {
      console.log(this.editedItem.setting_bank_id)

      await this.$store.dispatch('setting_bank/createSettingBank', {
        data: {
          item_data: this.editedItem,
        },
      })

      this.closeCreate()
      await this.initialize()
    },
  },
}
</script>

<style scoped>
#setting-bank-table {
  margin-top: 10px;
}
</style>
